.rating-page {
  max-width: 800px;
  margin: 5% auto;
  padding: 20px;
  background-color: #f5f5f5;
  border: 1px solid #ccc; 
  border-radius: 10px; 
  text-align: center;
  font-family: Arial, sans-serif;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #4285f4;
  text-align: center;
}

.question {
  margin-bottom: 20px;
}

.question p {
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 1.4;
  color: #333;
  font-family: "Times New Roman", Times, serif;
}

.star-rating {
  font-size: 0; /* Remove whitespace between inline-block elements */
}

.star {
  display: inline-block;
  font-size: 24px;
  color: #ddd;
  cursor: pointer;
  
}

.star-filled {
  color: #FFD700;
}

.star-empty {
  color: #ddd;
}

.selected-rating-label {
  font-weight: bold;
  margin-top: 10px;
  font-size: 16px;
  color: #4285f4;
}

button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: #edf0f4;
  color: #4285f4;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #1c5fac;
}

button:disabled {
  background-color: #4285f4;
  cursor: not-allowed;
}

.star-rating input[type='radio'] {
  display: none;
}

.star-rating label {
  font-size: 0;
}

.star-rating label::before {
  content: '★';
  font-size: 24px;
}

.star-rating .star {
  display: inline-block;
  cursor: pointer;
}

.star-rating .star::before {
  content: '★';
  font-size: 24px;
}

.star-rating .star-empty::before {
  color: #ddd;
}

.star-rating .star-filled::before {
  color: #FFD700;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
}

input[type='text'] {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
