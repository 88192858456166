.card-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.card input[type='radio'] {
  margin-right: 5px;
}

.card input[type='file'] {
  margin-top: 5px;
}

@media (max-width: 768px) {
  .card-container {
    flex-direction: column;
    align-items: center;
  }

  .card {
    width: 100%;
  }
}

body {
  font-family: Arial, sans-serif;
  color: #333;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

h2 {
  font-size: 1.5rem;
  margin-top: 0;
}

label {
  font-size: 1rem;
}

input[type='radio'] {
  margin-right: 5px;
}

input[type='file'] {
  margin-top: 5px;
}

button {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:focus {
  outline: none;
}

button:hover {
  background-color: #0056b3;
}

button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
