.card {
  padding: 20px;
  width: 100%;
  background-color: #f2f2f2;
  border: 2px solid blue;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif; 
}

.card-footer {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-content {
  display: flex;
  align-items: center;
  justify-content:center
}

.card-content > div {
  width: 90%;
  display: flex;
  flex-direction: column;
}

.recommendation-section {
  display: flex;
  align-items: center;
}

.stars-icon {
  font-size: 1.5rem;
  margin-right: 5px;
  color: gold;
}

.stars-count {
  font-weight: bold;
  margin-left: 5px;
}

.view-nomination-btn {
  padding: 10px;
  background-color: rgb(20, 143, 230);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-family: Arial, sans-serif; /* Update font family here */
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #4285f4;
}

.declaration {
  text-align: center;
  margin: auto;
  width: 90%;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.declaration-title {
  font-size: 20px;
  font-weight: bold;
}

.declaration-content {
  margin-top: 10px;
}

.question-table tr:not(:last-child) {
  margin-bottom: 10px;
}

.question-table td {
  padding: 8px;
}
